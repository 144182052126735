let definitions = {
  S2: "User's cookie or session token does not invalidate on the server-side",
  S3: "There is no bruteforce protection or weak password policy is used",
  S4: "An application does not use modern security headers or use them insecurely (clickjacking, CORS mistakes, HSTS, CSP)",
  S5: "An attacker could force user to perform request and an application not validate if it was forced (CSRF)",
  S6: "Session tokens are transmitted insecurely (ex. GET request)",
  S7: "An attacker can confuse a client with visually similar application",
  S8: "An attacker who gets login and password for high-privileged accounts can reuse it (No 2FA)",
  S9: "An Application use Authentication Protocols: LDAP/Kerberos/OAuth2/SAML/RADIUS without proper hardening",
  S10: "An attacker can bypass authentication because it is not enforced at all endpoints: API, mobile, etc.",
  SJ: "User credentials stored on the client insecurely",
  SQ: "An attacker could reuse temporary secrets: ex. account activation links, password reset link etc.",
  SK: "An attacker could get user secrets: ex. password stored as clear-text in the DB",

  T3: "An attacker can act as a 'man in the middle' because you don't authenticate endpoints of a network connection",
  T4: "An attacker can get cryptographic keys material and other secrets because they are not stored by using key vaults or API based alternatives",
  T5: "An attacker can access data because it has been obfuscated or encoded rather than encrypted",
  T6: "An attacker can take advantage of your custom key exchange or integrity control which you built instead of using standard crypto",
  T7: "An attacker can force an application to perform two or more sensitiive operations simultaneously without proper controls: ex. Race Condition",
  T8: "An attacker can upload files to places your code relies on: ex. update repositories, runtime files locations",
  T9: "An attacker can bypass validation because you deny invalid data rather then accept only valid data, sanitize invalid data instead of dropping it",
  T10: "An attacker can control the DOM of the application's page that could be visited by other users: (ex. XSS, Open-Redirect)",
  TJ: "An attacker can write to some resource because permissions are granted to the world or there are no ACLs",
  TQ: "An attacker can controll program execution flow by sending special prepared input data: ex. SQL injections, LDAP injection",
  TK: "An attacker can execute code in target machine",

  R10: "An attacker can edit logs and confuse the traces of his actions",
  RJ: "The application has no logs implemented by development team",
  RQ: "High privileged functions are not log",
  RK: "The system has no logs configured by sysadmins",

  I1: "An attacker can collect sensitive information via the search engines: ex. Shodan, Censys, Google",
  I2: "An attacker can obtain sensitive information in the application response",
  I3: "An attacker can read application logs",
  I4: "An attacker can abuse sensitive information stored on client side",
  I5: "An attacker can see error messages with security sensitive content: ex. logs, stack-traces",
  I6: "An attacker can see other users' information in the application response",
  I7: "An attacker can retrive encrypted or hashed data because you use weak algorithm",
  I8: "An attacker can abuse outdated or insecure cryptographic libraries",
  I9: "An attacker can predict sensitive identifiers because you rely on insecure random functions or don't use them",
  I10: "An attacker can discover sensitive files and directories stored in public folder",
  IJ: "An attacker can read some resource because permissions are granted to the world or there are no ACLs",
  IQ:"An attacker can obtain credentials used: ex. source code",
  IK: "An attacker can read network information because there's no cryptography used",

  D10:"An attacker is able to DOS some function or subsystem which is used by the application",
  DJ: "An attacker can make your system unusable or unavailable but the problem goes away when the attacker stops",
  DQ: "An authenticated attacker can make a server unavailable or unusable",
  DK: "An attacker can make a server unavailable or unusable without ever authenticating",

  E7: "A high-privileged operating system account is used for application components and services",
  E8: "Your code makes access control decisions all over the place, rather than with a security kernel",
  E9: "An attaker can misuse an application functions (ex. SSRF)",
  E10: "An attacker can change access lists or security configuration",
  EJ: "An attacker can access application functions, objects, or properties he is not authorized to access",
  EQ: "A regular user can execute methods and functions that require higher privileges by directly accessing them",
  EK: "An attacker can execute code in target machine",
}

export function getThreatDescription(card) {
  if (card in definitions) {
    return definitions[card];
  }
  return "";
}
